:root {
  --tb-blue: #001d42;
  --tb-green: #27f3b5;
  --tb-gray: #fbfffd;
}

/*.container {*/
/*  max-width: 1024px;*/
/*  margin: 0 auto;*/
/*}*/

/*a {*/
/*  color: #FFFFFF;*/
/*}*/

/*a:hover {*/
/*  color: #FFFFFF;*/
/*}*/

/*h1, h2, h3, h4, h5, h6, strong, b {*/
/*  font-family: 'Poppins', sans-serif;*/
/*  font-weight: 700;*/
/*  color: var(--tb-blue);*/
/*}*/

.shadow {
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
}

/*background body*/

#site {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  background-color: #fbfcfd !important;
}

#site .ant-layout-header {
  background-color: var(--tb-blue);
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: end;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

#site .ant-layout-header.headerLandPage {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: end;
  color: #000;
  position: relative;
  background: #fff !important;
  z-index: 1;
}

/*.logo {*/
/*  text-align: center;*/
/*  padding: 40px 0;*/
/*}*/
/*.logo img {*/
/*  width: 120px;*/
/*}*/

/*#site .ant-btn {*/
/*  height: 40px;*/
/* min-width: 100px;*/
/* padding: 0 30px !important;*/
/* display: flex;*/
/* align-items: center;*/
/* justify-content: center;*/
/* border-radius:40px;*/
/* border-width: 2px;*/
/* border-style: solid;*/
/* border-color: var(--tb-green);*/
/* color: var(--tb-blue);*/
/* background-color:#fff;*/
/* font-size: 17px;*/
/* text-shadow: none !important;*/
/* margin-right: 20px;*/
/* font-weight: bold;*/
/*}*/
#site .ant-btn span {
  font-weight: bold !important;
}

/*#site .ant-btn-primary {*/
/*  border-width: 2px;*/
/*  border-style: solid;*/
/*  border-color: var(--tb-green);*/
/*  color: var(--tb-blue);*/
/*  background-color: var(--tb-green);*/
/*}*/

/*#site .ant-btn-sm {*/
/*  height: 22px;*/
/*  font-size: 13px;*/
/*}*/

/*#site .ant-btn-primary:hover,*/
/*#site .ant-btn-primary:active,*/
/*#site .ant-btn-primary-secondary:hover,*/
/*#site .ant-btn-primary-secondary:active {*/
/*  background-color: #21d49f;*/
/*  border-color: #21d49f;*/
/*}*/

#site .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: var(--tb-blue);
}

.page-content {
  min-height: calc(100vh - 64px);
}

.sidebar {
  background-color: white;
}

.dropdown-user {
  width: 160px;
}

.dropdown-user .ant-menu-submenu-title {
  font-weight: 700;
  font-size: 15pt;
  color: var(--tb-blue);
}

/*.ant-menu-item .ant-menu-item-only-child {*/
/*  font-weight: lighter !important;*/
/*}*/
